<template>
  <b-modal
    v-model="_state"
    size="lg"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @show="initForm"
  >
    <template v-slot:modal-title>
      <h5 class="mb-0 text-secondary">
        {{ $t("page.condominium.createUser") }}
      </h5>
    </template>
    <template v-slot:default>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <b-form>
          <b-row class="no-gutters ml-n3">
            <b-col class="col-12 col-md-6 pl-3">
              <form-input
                v-model="user.name"
                label="requiredField.name"
                veeName="name"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input
                v-model="user.surname"
                label="requiredField.surname"
                veeName="surname"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-12 col-md-6 col-lg-4 pl-3">
              <form-input
                v-model="user.email"
                label="email"
                veeName="email"
                :veeRules="{ email: true }"
                @input="user.email = user.email.toLowerCase()"
              />
            </b-col>
            <b-col class="col-12 col-md-6 col-lg-4 pl-3">
              <form-input v-model="user.telephone" label="telephone" />
            </b-col>
            <b-col class="col-12 col-lg-4 pl-3">
              <form-input
                v-if="$is1Board"
                v-model="user.role"
                label="requiredField.roleInCondominium"
                veeName="administrator"
                :veeRules="{ required: true }"
              />
              <form-select
                v-else
                v-model="user.role"
                label="requiredField.roleInCondominium"
                veeName="administrator"
                :veeRules="{ required: true, select: true }"
              >
                <b-form-select-option
                  v-for="(role, index) in userRoleOptions"
                  :key="index"
                  :value="role"
                >
                  {{ $t(`userRoles.${role}`) }}
                </b-form-select-option>
              </form-select>
            </b-col>
            <div class="w-100 ml-3 my-4" />
            <b-col class="col-12 col-md-6 pl-3">
              <form-input v-model="user.address" label="address" />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input v-model="user.city" label="city" />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input v-model="user.province" label="province" />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input v-model="user.zip" label="cap" />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <div>
                <strong>Groups</strong>
                <div>{{ user.group ? "In Group" : "-" }}</div>
              </div>
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <base-switcher
                v-model="insertionPermission"
                name="contactInsertion"
                switchLabel="page.condominium.contact-insertion"
                sync
              />
            </b-col>
          </b-row>
        </b-form>
        <hr class="my-3 mx-n3" />
        <b-row class="no-gutters justify-content-end">
          <b-button
            variant="outline-dark"
            class="mr-1"
            @click="setModalState(false)"
          >
            {{ $t("button.cancel") }}
          </b-button>
          <b-button
            class="text-white"
            variant="primary"
            :disabled="invalid"
            @click="createUser"
          >
            {{ $t("button.send") }}
          </b-button>
        </b-row>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import { appMixin } from "./../../mixins";

import FormInput from "./../../../../console-components/BaseFormInput";
import FormSelect from "./../../../../console-components/BaseFormSelect";
import BaseSwitcher from "./../Base/Switcher.vue";
export default {
  components: {
    FormInput,
    FormSelect,
    BaseSwitcher
  },
  props: {
    state: {
      type: Boolean,
      default: false
    }
  },
  mixins: [appMixin],
  data() {
    return {
      user: {},
      userRoleOptions: ["doorman", "director", "owner", "tenant"],
      insertionPermission: false
    };
  },
  computed: {
    _state: {
      get() {
        return this.state;
      },
      set(value) {
        this.setModalState(value);
      }
    },
    currentCondominium() {
      return this.$store.getters["condominium/getCurrentCondominium"];
    }
  },
  methods: {
    initForm() {
      this.user = {
        name: "",
        surname: "",
        email: "",
        telephone: "",
        address: "",
        city: "",
        province: "",
        zip: "",
        role: ""
      };
    },
    async createUser() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        const newUser = await this.$store.dispatch("user/createUser", {
          userInfo: this.getProfilePayload()
        });

        if (this.user.role)
          await this.$store.dispatch("user/addUserCondominium", {
            userId: newUser.id,
            condominiumId: this.currentCondominium._id,
            payload: {
              role: this.user.role
            }
          });

        await this.$store.dispatch("condominium/retrieveCondominiumUsers");
        await this.$store.dispatch(
          "condominium/retrieveCondominium",
          this.currentCondominium._id
        );

        this.setModalState(false);
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    getProfilePayload() {
      const data = {
        name: this.user.name,
        surname: this.user.surname,
        email: this.user.email,
        telephone: this.user.telephone,
        address: this.user.address,
        city: this.user.city,
        province: this.user.province,
        zip: this.user.zip,
        type: "guest"
      };
      if (this.user.email) data.type = "consumer";

      return data;
    },
    setModalState(value) {
      this.$emit("update:state", value);
    }
  }
};
</script>

<style lang="scss" scoped></style>
