<template>
  <div>
    <assignment-table
      :assignments="formatedAssignemnts"
      :fields="fields"
      :actions="actions"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    />
  </div>
</template>

<script>
import userMixin from "../../mixins/userMixin";

import AssignmentTable from "../Assignment/AssignmentTable";
export default {
  components: {
    AssignmentTable
  },
  mixins: [userMixin],
  data() {
    return {
      dataLoaded: false,
      assignmentDetailsModalState: false,
      fields: [
        { key: "category", label: "category", sort: true },
        { key: "maintainerName", label: "nameAndSurname", sort: true },
        { key: "maintainerCompany", label: "companyName", sort: true },
        { key: "email", label: "email", sort: true },
        { key: "actions", label: "" }
      ],
      actions: [
        {
          label: "button.manage",
          variant: "secondary",
          callbackFunc: this.openAssignmentDetailsModal
        }
      ],
      sortBy: "category",
      sortDesc: false
    };
  },
  created() {
    this.$nextTick(() => {
      this.retrieveData();
    });
  },
  computed: {
    condominiumAssignments() {
      return this.$store.getters["groups/getGroups"]; // to change: condominium/getCondominiumAssignments
    },
    formatedAssignemnts() {
      if (this.dataLoaded && this.condominiumAssignments.length > 0) {
        return this.condominiumAssignments.map(item => {
          return {
            id: item._id,
            category: item.category || "-",
            maintainerName: this.$_getUserFullName(item.profile),
            maintainerCompany: item.company || "-",
            email: item.email || "-"
          };
        });
      } else {
        return [];
      }
    }
  },
  methods: {
    async retrieveData() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        console.log("TBD: retrieveCondominiumAssignemnts");
        console.log("TBD: groups/retrieveGroups"); // groups contains dynamic categories
      } catch (error) {
        console.log(error);
      } finally {
        this.dataLoaded = true;
        this.$store.commit("loader/RESET");
      }
    },
    retrieveAssignments() {
      //   console.log("TBD: retrieveCondominiumAssignemnts");
    },
    openAssignmentDetailsModal() {
      console.log("TBD: openAssignmentDetailsModal");
      //   this.assignmentDetailsModalState = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
