<template>
  <b-modal
    v-model="dialogState"
    size="xl"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
  >
    <template v-slot:modal-title>
      <h5 class="mb-0 text-secondary">
        {{ $t("page.condominium.editCondominium") }}
      </h5>
    </template>
    <template v-slot:default>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <b-form class="form-container">
          <b-row>
            <b-col class="col-8 pr-1">
              <form-input
                v-model="clonedcondominium.name"
                label="requiredField.condominiumName"
                veeName="condominiumName"
                :veeRules="{ required: true }"
              />
            </b-col>

            <b-col class="col-4 pl-1">
              <form-input
                v-model="clonedcondominium.unitsNum"
                label="requiredField.unitsNum"
                veeName="unitsNum"
                :veeRules="{ required: true, integer: true }"
              />
            </b-col>
          </b-row>
          <google-place-search
            v-model="searchAddress"
            label="requiredField.address"
            veeName="address"
            :address="clonedcondominium.address"
            :veeRules="{ required: true }"
            @setPlaceInfo="setPlaceInfo"
            @reset="resetAddressDetails"
          />
          <b-row v-if="hasAddressInfo" class="no-gutters">
            <b-col class="col-6 pr-1">
              <form-input
                v-model="clonedcondominium.zip"
                label="requiredField.cap"
                veeName="cap"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-6 pl-1">
              <form-input
                v-model="clonedcondominium.province"
                label="requiredField.province"
                veeName="province"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-6 pr-1">
              <form-input
                v-model="clonedcondominium.city"
                label="requiredField.city"
                veeName="city"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-6 pl-1">
              <form-input
                v-model="clonedcondominium.streetNumber"
                label="requiredField.streetNumber"
                veeName="streetNumber"
                :veeRules="{ required: true }"
              />
            </b-col>
          </b-row>
        </b-form>
        <hr class="my-3 mx-n3" />
        <b-row class="no-gutters justify-content-end">
          <b-button variant="outline-dark" class="mr-3" @click="closeModal">
            {{ $t("button.cancel") }}
          </b-button>
          <b-button
            class="text-white"
            variant="primary"
            :disabled="invalid"
            @click="updateCondominium"
          >
            {{ $t("button.insert") }}
          </b-button>
        </b-row>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import authMixin from "./../../mixins/authMixin";

import FormInput from "./../../../../console-components/BaseFormInput";
import GooglePlaceSearch from "./../Google/GooglePlaceSearch";
export default {
  components: {
    GooglePlaceSearch,
    FormInput
  },
  model: {
    prop: "dialog",
    event: "change"
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    condominium: {
      type: Object,
      required: true
    }
  },
  mixins: [authMixin],
  data() {
    return {
      clonedcondominium: {},
      searchAddress: ""
    };
  },
  created() {
    this.clonedcondominium = { ...this.condominium };
    this.searchAddress = this.clonedcondominium.address;
  },
  computed: {
    dialogState: {
      get() {
        return this.dialog;
      },
      set() {
        this.closeModal();
      }
    },
    hasAddressInfo() {
      return this.clonedcondominium.address.length > 0;
    }
  },
  methods: {
    resetAddressDetails() {
      this.clonedcondominium = { ...this.condominium };
    },
    closeModal() {
      this.$emit("change", false);
    },
    setPlaceInfo(place) {
      this.clonedcondominium.address = place.streetName;
      this.searchAddress = this.clonedcondominium.address;
      this.clonedcondominium.zip = place.postalCode;
      this.clonedcondominium.province = place.province;
      this.clonedcondominium.city = place.city;
      this.clonedcondominium.streetNumber = place.streetNumber;
      this.clonedcondominium.country = place.country;
      this.clonedcondominium.countryCode = place.countryShort;
      this.clonedcondominium.latitude = parseFloat(place.lat);
      this.clonedcondominium.longitude = parseFloat(place.lng);
      this.clonedcondominium.placeId = place.placeId;
    },
    async updateCondominium() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("condominium/updateCondominium", {
          condominiumId: this.clonedcondominium._id,
          payload: this.clonedcondominium
        });
        await this.$store.dispatch("condominium/retrieveCondominiums");
        await this.$store.dispatch("condominium/retrieveCondominiumsByFilters");
        this.closeModal();
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-container {
  min-height: 400px;
}
</style>
