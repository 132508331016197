<template>
  <div>
    <b-row class="no-gutters justify-content-end">
      <b-button
        v-if="!$_authUserIsDomi"
        class="mb-3"
        size="md"
        variant="secondary"
        @click="openCreateIssueModal"
      >
        {{ $t("button.createIssue") }}
      </b-button>
    </b-row>
    <issue-table
      :issues="formatedIssues"
      :fields="fields"
      :actions="actions"
      :sortBy.sync="sortBy"
      :sortDesc.sync="sortDesc"
    />
    <create-issue
      :state="createModalState"
      :condominium="currentCondominium"
      :callbackAfterCreate="retrieveIssuesCallback"
      @close="closeCreateIssueModal"
    />
    <edit-issue
      :state="editModalState"
      :issue="selectedIssue"
      :callbackAfterEdit="retrieveIssuesCallback"
      @close="closeEditIssueModal"
    />
  </div>
</template>

<script>
import { authMixin } from "./../../mixins";

import IssueTable from "./../Issue/IssueTable";
import CreateIssue from "./../Issue/CreateIssue";
import EditIssue from "./../Issue/EditIssue";
export default {
  components: {
    IssueTable,
    CreateIssue,
    EditIssue
  },
  mixins: [authMixin],
  data() {
    return {
      dataLoaded: false,
      createModalState: false,
      editModalState: false,
      fields: [
        { key: "createdAt", label: "date", sort: true },
        { key: "title", label: "issue", sort: true },
        { key: "status", label: "status", sort: false },
        { key: "totalComments", label: "commentsNumber", sort: true },
        { key: "maintainer", label: "maintainer", sort: true },
        { key: "actions", label: "" }
      ],
      actions: [
        {
          label: "button.manage",
          callbackFunc: this.openEditIssueModal
        }
      ],
      sortBy: "createdAt",
      sortDesc: true
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  computed: {
    currentCondominium() {
      return this.$store.getters["condominium/getCurrentCondominium"];
    },
    condominiumIssues() {
      return this.$store.getters["condominium/getCondominiumIssues"];
    },
    selectedIssue() {
      return this.$store.getters["issue/getCurrentIssue"];
    },
    formatedIssues() {
      if (this.dataLoaded && this.condominiumIssues.length > 0) {
        return this.condominiumIssues.map(item => {
          return {
            id: item._id,
            createdAt: item.createdAt,
            title: item.title,
            photos: item.photos,
            totalPhotos: item.photos.length,
            status: item.status,
            type: item.type,
            totalComments: item.comments.filter(comment => comment.ownerId)
              .length,
            maintainer: item.maintainerId
          };
        });
      } else return [];
    }
  },
  methods: {
    async init() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("condominium/retrieveCondominiumIssues");
        await this.$store.dispatch("maintainer/retrieveAdminMaintainers");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
        this.dataLoaded = true;
      }
    },
    async retrieveIssuesCallback() {
      await this.$store.dispatch("condominium/retrieveCondominiumIssues");
      await this.$store.dispatch(
        "condominium/retrieveCondominium",
        this.currentCondominium._id
      );
    },
    openEditIssueModal(id) {
      const selectedIssue = this.condominiumIssues.find(
        issue => issue._id === id
      );
      this.$store.commit("issue/SET_CURRENT_ISSUE", selectedIssue);
      this.editModalState = true;
    },
    openCreateIssueModal() {
      this.createModalState = true;
    },
    closeCreateIssueModal() {
      this.createModalState = false;
    },
    closeEditIssueModal() {
      this.editModalState = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.sort-icon {
  width: 20px;
}
</style>
