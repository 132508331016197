<template>
  <b-table
    :items="assignments"
    :fields="fields"
    :sort-by.sync="_sortBy"
    :sort-desc.sync="_sortDesc"
    responsive="sm"
    outlined
    hover
    show-empty
    small
  >
    <template #empty>
      <h6 class="text-center text-italic text-uppercase">
        {{ $t("page.condominium.emptyList") }}
      </h6>
    </template>
    <template #head()="data">
      <div
        :class="{ clickable: data.field.sort }"
        @click="data.field.sort && setSort(data.column)"
      >
        {{ $t(data.label) }}
        <svg
          v-if="data.column === _sortBy"
          viewBox="0 0 16 16"
          class="sort-icon"
        >
          <path fill-rule="evenodd" :d="sortIcon"></path>
        </svg>
      </div>
    </template>
    <template #cell(category)="data">
      {{ data.value }}
    </template>
    <template #cell(maintainerName)="data">
      {{ data.value }}
    </template>
    <template #cell(maintainerCompany)="data">
      {{ data.value }}
    </template>
    <template #cell(email)="data">
      {{ data.value }}
    </template>
    <template #cell(actions)="data">
      <b-row class="no-gutters justify-content-end">
        <b-col v-for="(action, index) in actions" :key="index" class="col-auto">
          <b-button
            size="sm"
            variant="secondary"
            class="ml-3"
            @click="action.callbackFunc(data.item.id)"
          >
            {{ $t(action.label) }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-table>
</template>

<script>
export default {
  props: {
    assignments: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    actions: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      default: ""
    },
    sortDesc: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sortIcon() {
      return this._sortDesc
        ? "M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
        : "M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z";
    },
    _sortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.$emit("update:sortBy", value);
      }
    },
    _sortDesc: {
      get() {
        return this.sortDesc;
      },
      set(value) {
        this.$emit("update:sortDesc", value);
      }
    }
  },
  methods: {
    setSort(newSortBy) {
      if (this._sortBy !== newSortBy) {
        this._sortBy = newSortBy;
        this._sortDesc = true;
      } else {
        this._sortDesc = !this._sortDesc;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sort-icon {
  width: 20px;
}
</style>
