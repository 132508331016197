<template>
  <div>
    <b-row class="no-gutters justify-content-end">
      <b-button
        class="mb-3"
        size="md"
        variant="secondary"
        @click="goToNewCommunicationPage"
      >
        {{ $t("button.createCommunication") }}
      </b-button>
    </b-row>
    <communication-table
      :communications="formatedCommunications"
      :fields="fields"
      :sortBy.sync="sortBy"
      :sortDesc.sync="sortDesc"
      :retrieveDataCallback="retrieveCommunicationsCallback"
    />
  </div>
</template>

<script>
import CommunicationTable from "./CommunicationTable";
export default {
  components: {
    CommunicationTable
  },
  data() {
    return {
      fields: [
        { key: "subject", label: "subject", sort: true },
        { key: "createdAt", label: "date", sort: true },
        { key: "body", label: "description", sort: true },
        { key: "readcounter", label: "readcounter", sort: false },
        { key: "documents", label: "documents", sort: true },
        { key: "attachments", label: "attachments" },
        { key: "download", label: "download" },
        { key: "status", label: "status" },
        { key: "details", label: "" }
      ],
      dataLoaded: false,
      sortBy: "createdAt",
      sortDesc: true
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.retrieveCommunications();
    });
  },
  computed: {
    condominiumCommunications() {
      return this.$store.getters["condominium/getCondominiumCommunications"];
    },
    formatedCommunications() {
      return this.dataLoaded && this.condominiumCommunications.length > 0
        ? this.condominiumCommunications
        : [];
    }
  },
  methods: {
    async retrieveCommunications() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch(
          "condominium/retrieveCondominiumCommunications"
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.dataLoaded = true;
        this.$store.commit("loader/RESET");
      }
    },
    retrieveCommunicationsCallback() {
      return this.$store.dispatch(
        "condominium/retrieveCondominiumCommunications"
      );
    },
    goToNewCommunicationPage() {
      this.$store.commit("communication/RESET");
      this.$store.commit(
        "communication/SET_PREV_ROUTE_NAME",
        "CondominiumManagement"
      );
      this.$router.push({ name: "NewCommunication" });
    }
  }
};
</script>

<style lang="scss" scoped>
.sort-icon {
  width: 20px;
}
</style>
